* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

body.shouldTransition {
  transition: background-color ease 0.5s, color ease 0.3s;
}

button {
  background: none;
  border: none;
  padding: 0;
}

button:hover {
  background-color: #323336;
  color: #fff;
}

@media print {
  .resume-wrapper {
    padding: 0 !important;
    max-width: 100% !important;
  }
}
